<template>
    <KalmModal modalTitle="Todo" @close="close">
        <template v-slot:body>
            <TodoForm :todo="todo"
                      :projectId="projectId"
                      :canevaId="this.canevaId"
                      :lastOrder="lastOrder"
                      :assignedToCurrentUser="assignedToCurrentUser"
                      :buttonName="this.buttonName"
                      @close="close">
            </TodoForm>
        </template>
    </KalmModal>
</template>

<script>
import KalmModal from '@/views/_core/components/KalmModal.vue';
import TodoForm from './TodoForm.vue';
export default {
    name: "TodoFormModal",
    props: {
        todo: Object,
        projectId: {
            type: String,
            required: false,
        },
        canevaId: {
            type: String,
            required: false,
        },
        lastOrder: Number,
        assignedToCurrentUser: {
            type: Boolean,
            required: false,
            default: false,
        },
        buttonName: {
            type: String,
            required: false,
            default: "Ajouter",
        },
    },
    components: {
        KalmModal,
        TodoForm,
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
}
</script>