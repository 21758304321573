<template>
    <div class="col-12 mb-5">
        <div class="d-flex align-items-center justify-content-between my-4">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bolder text-dark fs-3">Caneva - {{ caneva.name }}</span>
                </h3>
                <div v-if="!assignedToCurrentUser" class="card-toolbar">
                    <ul class="nav nav-pills nav-pills-sm nav-light">
                        <li class="nav-item">
                            <a class="nav-link btn btn-primary fw-bolder me-2" @click="addTodo">Ajouter une Todo</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link btn btn-primary fw-bolder me-2" @click="importCaneva(caneva.id)"><span class="bi bi-stars">Importer le caneva</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        <div class="card-body">
            <div v-if="loading">
                <div class="d-flex justify-content-center">
                    <div class="spinner spinner-primary spinner-center"></div>
                </div>
            </div>
            <div v-if="!getTodos().length && !loading" class="text-center w-25 mx-auto">
                <TodoEmptyState message="Aucune todo" buttonMessage="Ajouter une todo"
                    image="/assets/media/svg/empty-states/empty-todo.svg" @add="addTodo"/>
            </div>
            <draggable :list="getTodos()" :disabled="!enabled || !isDraggable"
                    item-key="id" class="list-group-item mb-8"
                    handle=".handle"
                    :component-data="{
                        tag: 'div',
                        id: 'todos',
                        type: 'transition-group',
                        name: !draggingTodo ? 'flip-list' : null
                    }" @start="draggingTodo = true" @end="draggingTodo = false; onDroppedTodo()" v-bind="dragTodoOptions" @add="onAddTodo" >
                <template #item="{ element: todo }">
                    <div class="list-group-item mb-8" :class="{ 'not-draggable': !enabled || !isDraggable }" id="todos">
                        <div class="list-group-item mb-5" :class="{ 'not-draggable': !enabled || !isDraggable }">
                            <div class="card card-stretch mb-4">
                                <div class="card-header border-0">
                                    <div class="d-flex">
                                        <div v-if="isDraggable" class="lot-drag-handle handle me-2" style="cursor: grab;">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                                style="width: 15px; height: 100%; display: block;" class="align-self-center">
                                                <path fill-opacity="0.2"
                                                    d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                                            </svg>
                                        </div>
                                        <h3 class="card-title">
                                            <span class="card-label fw-bolder text-dark fs-3 me-0">{{ todo.title }}</span>
                                        </h3>
                                    </div>
                                    <div class="card-toolbar">
                                        <ul class="nav nav-pills nav-pills-sm nav-light">
                                            <li class="nav-item">
                                                <EditButton @click="editTodo(todo)"></EditButton>
                                            </li>
                                            <li class="nav-item">
                                                <DeleteButton @click="showDeleteTodo(todo)"></DeleteButton>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <CanevaTodoItems :todo="todo"
                                                     :loading="this.loading"
                                                     :canevaId="this.caneva?.id"
                                                     @addTodoItem="showTodoItemForm"
                                                     @close="close"/>
                                    <button v-if="!modals.addTodoItem.show && this.selectedTodo !== element " @click="addTodoItem(todo)" type="button" class="btn btn-primary">Ajouter une
                                        tâche</button>
                                    <TodoItemForm class="mb-8" v-if="this.modals.addTodoItem.show && this.selectedTodo === todo"
                                                  :todo-id="this.selectedTodo.id"
                                                  @close="closeTodoItemForm"
                                                  :canevaId="this.caneva.id"
                                                  :basicInputs="true"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
        <DeleteTodoConfirmModal v-if="this.modals.deleteTodo.show" :todo="this.selectedTodo" @close="closeTodoDeleteModal" />
        <TodoFormModal v-if="modals.editTodo.show"
                       @close="closeTodoEditModal"
                       :todo="this.selectedTodo"
                       :projectId="null"
                       :canevaId="this.caneva.id"
                       :assignedToCurrentUser="false" />
    </div>
</template>

<script>
import CanevaTodoItems from './CanevaTodoItems.vue';
import EditButton from '@/views/_core/components/EditButton.vue';
import DeleteButton from '@/views/_core/components/DeleteButton.vue';
import TodoEmptyState from '@/views/todos/presentation/todos/components/TodoEmptyState.vue';
import TodoItemForm from '@/views/todos/presentation/todo-form/TodoItemForm.vue';
import DeleteTodoConfirmModal from '@/views/todos/presentation/todos/components/DeleteTodoConfirmModal.vue';
import TodoFormModal from '@/views/todos/presentation/todo-form/TodoFormModal.vue';
import draggable from 'vuedraggable';
import { BULK_UPDATE_TODO_ORDER } from '@/views/todos/data/todos_graphql';

export default {
    name: 'CanevaTodo',
    components: {
        EditButton,
        DeleteButton,
        TodoEmptyState,
        TodoItemForm,
        DeleteTodoConfirmModal,
        TodoFormModal,
        draggable,
        CanevaTodoItems,
    },
    props: {
        caneva: {
            type: Object,
            Required: true
        }
    },
    data() {
        return {
            loading: false,
            modals: {
                editTodo: {
                    show: false,
                    canevaId: null,
                },
                addTodoItem: {
                    show: false,
                },
                caneva: {
                    show: false,
                },
                deleteTodo: {
                    show: false,
                }
            },
            buttonName: "Ajouter",
            selectedTodo: null,
            draggingTodo: false,
            enabled: true,
            isDraggable: true,
            localTodos: [],
            lastStringifiedTodos: "",
        }
    },
    methods: {
        getTodos() {
            if (!this.caneva?.todos) return [];

            const stringifiedTodos = JSON.stringify(this.caneva?.todos.map(todo => {
                // eslint-disable-next-line no-unused-vars
                const { order, updatedAt, ...rest } = todo;
                return rest;
            }));

            if (this.lastStringifiedTodos !== stringifiedTodos) {
                this.localTodos = [...this.caneva?.todos];
                this.lastStringifiedTodos = stringifiedTodos;
            }
            return this.localTodos
        },
        editTodo(todo) {
            this.selectedTodo = todo;
            this.modals.editTodo.show = true;
            this.buttonName = "Modifier";
        },
        addTodoItem(todo) {
            this.modals.addTodoItem.show = true;
            this.selectedTodo = todo
        },
        closeTodoEditModal() {
            this.modals.editTodo.show = false;
            this.selectedTodo = null;
            this.$emit('close')
        },
        closeTodoDeleteModal() {
            this.modals.deleteTodo.show = false;
            this.selectedTodo = null;
            this.close()
        },
        closeTodoItemForm() {
            this.close()
            this.modals.addTodoItem.show = false;
            this.selectedTodo = null;
        },
        showDeleteTodo(todo) {
            this.modals.deleteTodo.show = true;
            this.selectedTodo = todo;
        },
        close() {
            this.$emit('close');
        },
        onDroppedTodo() {
            this.$apollo.mutate({
                mutation: BULK_UPDATE_TODO_ORDER,
                variables: {
                    todos: [...this.localTodos.map((todo, index) => (JSON.stringify({
                        id: todo.id,
                        order: index,
                    })))],
                },
            });
            this.$emit('close')
        },
        showTodoItemForm(todo) {
            this.modals.addTodoItem.show = true;
            this.selectedTodo = todo;
        },
        importCaneva(canevaId) {
            this.$emit('import-caneva', canevaId);
        },
        addTodo() {
            this.modals.editTodo.show = true;
            this.modals.editTodo.canevaId = this.caneva.id;
            this.buttonName = "Ajouter";
            this.$emit('close');
        },
    },
    computed: {
        dragTodoOptions() {
            return {
                animation: 200,
                group: "todo",
                ghostClass: 'ghost'
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>