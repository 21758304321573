<template>
  <div
    class="modal show d-block"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered mw-600px">
      <div class="modal-content mx-5">
        <div class="container px-10 py-10">
          <div class="modal-header p-0 d-flex justify-content-end border-0">
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-light-primary" @click="close()">
              <i class="bi bi-x-lg"></i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body p-0">
              <!--begin::Content-->
              <div class="d-flex flex-row-fluid justify-content-center">
                <!--begin::Form-->
                <div
                  ref="contactEditModalForm"
                  class="pb-5 w-100 w-sm-400px fv-plugins-bootstrap5 fv-plugins-framework"
                >
                  <!--begin::Step-->
                  <div class="pb-5 completed">
                    <div class="w-100">
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h3 class="fw-bolder text-dark display-6">
                          Modifier le contact
                        </h3>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Form Group-->
                      <div
                        class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                      >
                        <label class="fs-6 fw-bolder text-dark form-label"
                          >Nom(s)</label
                        >
                        <input
                          v-model="contact.name"
                          type="text"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="Jean & Laura Dupont"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div
                        class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                      >
                        <label class="fs-6 fw-bolder text-dark form-label"
                          >Téléphone</label
                        >
                        <input
                          v-model="contact.phone"
                          type="text"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="+33 6 23 45 67 89"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div
                        v-if="contact.phone"
                        class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                      >
                        <label class="fs-6 fw-bolder text-dark form-label"
                          >Téléphone <span class="text-muted">(2)</span></label
                        >
                        <input
                          v-model="contact.phone2"
                          type="text"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="01 23 45 67 89"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div
                        class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                      >
                        <label class="fs-6 fw-bolder text-dark form-label"
                          >E-mail</label
                        >
                        <input
                          v-model="contact.email"
                          type="text"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="jean.dupont@gmail.com"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div
                        class="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                      >
                        <label class="fs-6 fw-bolder text-dark form-label"
                          >Entreprise</label
                        >
                        <label class="ms-1 fs-8 text-muted form-label"
                          >(Optionnel)</label
                        >
                        <input
                          v-model="contact.company"
                          type="text"
                          class="form-control form-control-lg form-control-solid"
                          placeholder="Sanzot SARL"
                        />
                      </div>
                      <!--end::Form Group-->

                      <!--begin::Form Group-->
                      <div
                        class="fv-row"
                        v-for="typeOption in contact_modal_type_options"
                        :key="typeOption.id"
                      >
                        <!--begin:Option-->
                        <label class="d-flex flex-stack mb-6 cursor-pointer">
                          <span class="d-flex align-items-center me-2">
                            <span class="symbol symbol-50px me-6">
                              <span class="symbol-label" :class="typeOption.bg">
                                <i class="fs-1 bi" :class="typeOption.icon"></i>
                              </span>
                            </span>
                            <span class="d-flex flex-column">
                              <span class="fw-bolder fs-6">{{
                                typeOption.title
                              }}</span>
                              <span class="fs-7 text-muted">{{
                                typeOption.subtitle
                              }}</span>
                            </span>
                          </span>
                          <span
                            class="form-check form-check-custom form-check-solid"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              v-model="contact.type"
                              :value="typeOption.id"
                            />
                          </span>
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Form Group-->

                      <div
                        v-if="
                          contact.type === 'CUSTOMER_PRO' ||
                          contact.type === 'COMPANY'
                        "
                      >
                        <!--begin::Form Group-->
                        <div
                          class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                        >
                          <label class="fs-6 fw-bolder text-dark form-label"
                            >SIRET</label
                          >
                          <label class="ms-1 fs-8 text-muted form-label"
                            >(Optionnel)</label
                          >
                          <input
                            v-model="contact.infoSiret"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="339 863 417 00418"
                          />
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div
                          class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                        >
                          <label class="fs-6 fw-bolder text-dark form-label"
                            >Corps d'état</label
                          >
                          <label class="ms-1 fs-8 text-muted form-label"
                            >(Optionnel)</label
                          >
                          <input
                            v-model="contact.infoCorps"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="Électricité"
                          />
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div
                          class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                        >
                          <label class="fs-6 fw-bolder text-dark form-label"
                            >Site web</label
                          >
                          <label class="ms-1 fs-8 text-muted form-label"
                            >(Optionnel)</label
                          >
                          <input
                            v-model="contact.infoWebsite"
                            type="url"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="https://www.kalm.ai"
                          />
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div
                          class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                        >
                          <label class="fs-6 fw-bolder text-dark form-label"
                            >N° TVA</label
                          >
                          <label class="ms-1 fs-8 text-muted form-label"
                            >(Optionnel)</label
                          >
                          <input
                            v-model="contact.infoTva"
                            type="text"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="FR03339863417"
                          />
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div
                          class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                        >
                          <label class="fs-6 fw-bolder text-dark form-label"
                            >Adresse</label
                          >
                          <label class="ms-1 fs-8 text-muted form-label"
                            >(Optionnel)</label
                          >
                          <textarea
                            v-model="contact.infoAddress"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="15 rue du Petit Musc&#10;75004 Paris 4ème"
                          ></textarea>
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div
                          class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                        >
                          <label class="fs-6 fw-bolder text-dark form-label"
                            >Assurance</label
                          >
                          <label class="ms-1 fs-8 text-muted form-label"
                            >(Lien vers le fichier ou Numéro)</label
                          >
                          <div class="input-group align-middle">
                            <input
                              v-model="contact.infoInsurance"
                              type="text"
                              class="form-control form-control-lg form-control-solid"
                              placeholder="https://drive.google.com/uazdAZOjazdlakzdLKAZlkaz"
                            />
                            <a
                              v-if="
                                contact.infoInsurance &&
                                contact.infoInsurance.startsWith(
                                  'http'
                                )
                              "
                              :href="
                                contact.infoInsurance || '#'
                              "
                              class="input-group-text border-0"
                              :class="{
                                'cursor-default':
                                  !contact.infoInsurance,
                              }"
                              :target="
                                contact.infoInsurance
                                  ? '_blank'
                                  : ''
                              "
                              ><span class="bi bi-link"></span
                            ></a>
                          </div>
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div
                          class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                        >
                          <label class="fs-6 fw-bolder text-dark form-label"
                            >Commentaire</label
                          >
                          <label class="ms-1 fs-8 text-muted form-label"
                            >(Optionnel)</label
                          >
                          <textarea
                            v-model="contact.infoComment"
                            class="form-control form-control-lg form-control-solid"
                            placeholder="Électricité générale"
                          ></textarea>
                        </div>
                        <!--end::Form Group-->
                      </div>
                      <div class="mt-5">
                        <p class="mt-7 text-center">
                          Remplacer l'affichage par défaut du contact par des
                          infos personnalisées :
                        </p>

                        <!--begin::Form Group-->
                        <div
                          class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                        >
                          <label class="fs-6 fw-bolder text-dark form-label"
                            >Affichage personnalisé - Ligne 1</label
                          >
                          <label class="ms-1 fs-8 text-muted form-label"
                            >(Optionnel)</label
                          >
                          <input
                            v-model="contact.infoLine1"
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="contact.name"
                          />
                        </div>
                        <!--end::Form Group-->

                        <!--begin::Form Group-->
                        <div
                          class="fv-row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                        >
                          <label class="fs-6 fw-bolder text-dark form-label"
                            >Affichage personnalisé - Ligne 2+</label
                          >
                          <label class="ms-1 fs-8 text-muted form-label"
                            >(Optionnel)</label
                          >
                          <textarea
                            v-model="contact.infoLine2"
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="
                              contact.email +
                              ' - ' +
                              contact.phone
                            "
                          ></textarea>
                        </div>
                        <!--end::Form Group-->
                      </div>
                    </div>
                  </div>
                  <!--end::Step-->

                  <!--begin::Actions-->
                  <div class="d-flex justify-content-end">
                    <div>
                      <button class="btn btn-lg btn-light" @click="close()">
                        Annuler
                      </button>
                      <button
                        class="btn ms-2 btn-lg btn-primary btn-active-light-primary"
                        @click="validate()"
                      >
                        Modifier
                      </button>
                    </div>
                  </div>
                  <!--end::Actions-->
                </div>
                <!--end::Form-->
              </div>
              <!--end::Content-->
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MUTATION_CONTACT_UPDATE } from "../../data/contacts_graphql";

export default {
    name: "ContactUpdateModal",
    props: {
        modelValue: {
            type: [Object, Array],
            required: true,
        },
        callback: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            contact_modal_type_options: [
                {
                    id: "CUSTOMER_INDIVIDUAL",
                    title: "Client — Particulier",
                    subtitle: "Un particulier, client ou prospect",
                    icon: "bi-person",
                    bg: "bg-light-success",
                    checked: true,
                },
                {
                    id: "CUSTOMER_PRO",
                    title: "Client — Pro",
                    subtitle: "Un professionnel, client ou prospect",
                    icon: "bi-person-check",
                    bg: "bg-light-info",
                },
                {
                    id: "COMPANY",
                    title: "Entreprise",
                    subtitle: "Professionnel - Prestataire ou fournisseur",
                    icon: "bi-building",
                    bg: "bg-light-warning",
                },
            ],
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        validate() {
            const contactId = this.contact.id;
            const newContact = {
                name: this.contact.name,
                email: this.contact.email,
                phone: this.contact.phone,
                phone2: this.contact.phone2,
                company: this.contact.company,
                type: this.contact.type,
                infoSiret: this.contact.infoSiret || "",
                infoInsurance: this.contact.infoInsurance || "",
                infoComment: this.contact.infoComment || "",
                infoAddress: this.contact.infoAddress || "",
                infoTva: this.contact.infoTva || "",
                infoCorps: this.contact.infoCorps || "",
                infoLine1: this.contact.infoLine1 || "",
                infoLine2: this.contact.infoLine2 || "",
                infoWebsite: this.contact.infoWebsite || "",
            };
            if (!contactId) return;

            this.$apollo.mutate({
                mutation: MUTATION_CONTACT_UPDATE,
                variables: {
                    contactId,
                    newContact,
                },
            }).then(() => {
                this.close()
                this.callback()
            }).catch(error => {
                console.error(error)
            });
        }
    },
    computed: {
        contact: {
            get() {
                return this.modelValue;
            },
            set(value) {
              this.$emit("update:modelValue", value);
            },
        },
    },
};
</script>

<style lang="scss" scoped></style>
