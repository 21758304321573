<template>
    <div v-if="loading">
        <div class="d-flex justify-content-center">
            <div class="spinner spinner-primary spinner-center"></div>
        </div>
    </div>
    <div v-if="!getItems().length && !loading" class="text-center w-25 mx-auto">
        <TodoEmptyState message="Aucune tâche" buttonMessage="Ajouter une tâche"
            image="/assets/media/svg/empty-states/empty-todo.svg" @add="$emit('add-todo-item', todo)"/>
    </div>

    <draggable :list="getItems()" :disabled="!enabled || !isDraggable"
        item-key="id" class="list-group mb-8"
        handle=".handle"
        :component-data="{
            tag: 'div',
            type: 'transition-group',
            id: 'items',
            name: !draggingTodoItems ? 'flip-list' : null
        }" @start="draggingTodoItems = true" @end="draggingTodoItems = false; onDroppedTodoItems(todo)" v-bind="dragTodoItemsOptions" @add="onAddTodoItems">
        <template #item="{ element: todoItem }">
            <div class="list-group-item" :class="{ 'not-draggable': !enabled || !isDraggable }" id="items">
                <TodoItem :todo-item="todoItem"
                          :todo-id="todo.id"
                          :is-draggable="true"
                          :basicButtons="true"
                          :canevaId="this.canevaId"
                          @addTodoItem="$emit('add-todo-item', todo)"
                          @close="close"/>
            </div>
        </template>
    </draggable>
</template>

<script>
import TodoItem from '@/views/todos/presentation/todos/components/TodoItem.vue';
import { BULK_UPDATE_TODO_ITEM_ORDER } from '@/views/todos/data/todos_graphql';
import draggable from 'vuedraggable';

export default {
    name: "CanevaTodoItems",
    props: {
        canevaId: {
            type: String,
            required: false,
        },
        todo: {
            type: Object,
            Required: true
        },
        loading: {
            type: Boolean,
            Required: true
        }
    },
    data() {
        return {
            localItems: [],
            lastStringifiedItems: "",
            isDraggable: true,
            enabled: true,
            draggingTodoItems: false
        }
    },
    methods: {
        getItems() {
            const stringifiedItems = JSON.stringify(this.todo.todoItems.map(item => {
                // eslint-disable-next-line no-unused-vars
                const { order, updatedAt, ...rest } = item;
                return rest;
            }));

            if (this.lastStringifiedItems !== stringifiedItems) {
                this.localItems = [...this.todo.todoItems];
                this.lastStringifiedItems = stringifiedItems;
            }
            return this.localItems
        },
        onAddTodoItems(todo) {
            // Will save the new order and new todoId
            this.onDroppedTodoItems(todo);
        },
        onDroppedTodoItems(selectedTodo) {
            this.$apollo.mutate({
                mutation: BULK_UPDATE_TODO_ITEM_ORDER,
                variables: {
                    todoItems: [...this.localItems.map((todo, index) => (JSON.stringify({
                        id: todo.id,
                        order: index,
                        todoId: selectedTodo.id,
                    })))],
                },
            });
            this.close()
        },
        close() {
            this.$emit('close')
        }
    },
    components: {
        TodoItem,
        draggable
    },
    computed: {
        dragTodoItemsOptions() {
            return {
                animation: 200,
                group: "todoItems",
                ghostClass: "ghost",
            };
        },
    }
}
</script>

<style lang="scss" scoped>

</style>