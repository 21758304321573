import gql from 'graphql-tag';

export const GET_LINKS = gql`
    query LinkByFile($fileId: UUID!) {
        linkByFile(fileId: $fileId) {
            id
            expirationDate
            createdAt
            updatedAt
            linkEvents {
                id
                name
                createdAt
                updatedAt
            }
        }
    }
`;

export const LINK_DELETE = gql`
    mutation LinkDelete($id: UUID!) {
        linkDelete(id: $id) {
            deleted
        }
    }
`;

export const LINK_CREATE = gql`
    mutation LinkCreate($fileId: UUID!, $expirationDate: DateTime) {
        linkCreate(fileId: $fileId, expirationDate: $expirationDate) {
            link {
                id
                expirationDate
                createdAt
                updatedAt
            }
        }
    }
`;