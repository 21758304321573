import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'

import { StarterKit } from "@tiptap/starter-kit";

import LotOuvrageComponent from './LotOuvrageComponent.vue'
import LotTextComponent from "./LotTextComponent.vue";
import LotGroupComponent from "./LotGroupComponent.vue";
import LotHeadingComponent from "./LotHeadingComponent.vue";
import LotContentComponent from "./LotContentComponent.vue";
import {Plugin} from "@tiptap/pm/state";
import {Paragraph} from "@tiptap/extension-paragraph";
import LotParagraphComponent from "@/views/project/description/LotParagraphComponent";
import {v4} from "uuid";

import { findParentNodeOfType } from "prosemirror-utils";

export const lotHeading = Node.create({
    name: "lotHeading",

    disableDropCursor: true,

    addProseMirrorPlugins() {
        return [
            new Plugin({
                name: 'disableDropCursor',
                disableDropCursor () {
                    return true;
                }
            }),
        ];
    },

    addAttributes() {
        return {
            fullname: {
                type: String,
                default: "Lot n°Y – Z"
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'lot-heading',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['lot-heading', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(LotHeadingComponent)
    },
});

export const lotOuvrage = Node.create({
    name: 'lotOuvrage',
    priority: 10000,

    group: 'lotContentGroup',

    extensions: [
        StarterKit.configure({
            paragraph: false,
        }),
        Paragraph.extend({
            priority: 9999
        })
    ],

    content: 'heading (paragraph|list|image)*',

    draggable: true,
    disableDropCursor: true,

    addAttributes() {
        return {
            location: {
                type: String,
                default: "",
            },
            locations: {
                type: Array,
                default: [],
            },
            qt: {
                type: String,
                default: "",
            },
            unit: {
                type: String,
                default: "",
            },
            unitPrice: {
                type: String,
                default: "",
            },
            numero: {
                type: String,
                default: "",
            },
            id: {
                type: String,
                default: null,
            },
            printHidden: {
                type: Boolean,
                default: false,
            }
        }
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                appendTransaction: (transactions, oldState, newState) => {

                    const newTr = newState.tr
                    let modified = false

                    let count = 0;
                    let lotNumber = "";
                    let lotSum = 0;
                    let currentLotPos = null

                    newState.doc.descendants((node, pos) => {
                        if (!!node.type && (node.type.name === 'docLot')) {
                            lotNumber = node?.attrs?.fullName?.split(' ')[1]?.substring(2) || "X";
                            count = 0;
                            lotSum = 0;
                            currentLotPos = pos;
                        }
                        if (!!node.type && (node.type.name === 'lotOuvrage')) {
                            let { id, numero, qt, unitPrice, ...rest } = node.attrs

                            // build the ouvrage numero
                            const newOuvrageNumero = lotNumber + '.' + ++count;

                            let ouvrageCalculatedPrice = 0
                            try {
                                ouvrageCalculatedPrice = qt * unitPrice;
                            }
                            catch {
                                ouvrageCalculatedPrice = 0
                            }
                            lotSum += ouvrageCalculatedPrice;

                            // add unique id to ouvrage if needed
                            if (id === undefined || id === null || id === '') {
                                id = v4();
                                modified = true;
                            }

                            // add unique numero to ouvrage if needed
                            if(numero === undefined || numero === null || numero === '' || numero !== newOuvrageNumero) {
                                numero = newOuvrageNumero;
                                modified = true;
                            }

                            // commit new value if needed
                            if(modified) {
                                newTr.setNodeMarkup(pos, undefined, { id, numero, qt, unitPrice, ...rest })
                            }
                            newTr.setNodeAttribute(currentLotPos, "calculatedSum", lotSum)
                        }
                    })

                    return newTr
                }
            }),
        ]
    },


    parseHTML() {
        return [
            {
                tag: 'ouvrage',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['ouvrage', mergeAttributes(HTMLAttributes, { 'data-type': 'draggable-item' }), 0]
    },

    addNodeView() {
        return VueNodeViewRenderer(LotOuvrageComponent)
    },
})

export const lotText = Node.create({
    name: 'lotText',
    priority: 1200,

    group: 'lotContentGroup',

    extensions: [
        StarterKit.configure({
            paragraph: false,
        }),
        Paragraph.extend({
            priority: 999,
        }),
    ],

    content: '(paragraph|heading|list|image)+',

    draggable: true,
    disableDropCursor: true,


    parseHTML() {
        return [
            {
                tag: 'lot-text',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['lot-text', mergeAttributes(HTMLAttributes), 0]
    },

    addNodeView() {
        return VueNodeViewRenderer(LotTextComponent)
    },
})

export const lotGroup = Node.create({
    name: 'lotGroup',
    priority: 1200,

    group: 'lotContentGroup',

    content: 'heading (lotParagraph|list|lotOuvrage|lotText|image)*',


    draggable: true,
    disableDropCursor: true,


    parseHTML() {
        return [
            {
                tag: 'lot-group',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['lot-group', mergeAttributes(HTMLAttributes, { 'data-type': 'draggable-item' }), 0]
    },

    addNodeView() {
        return VueNodeViewRenderer(LotGroupComponent)
    },
})

export const lotParagraph = Node.create({

    name: "lotParagraph",
    priority: 1000,

    extensions: [
        StarterKit
    ],

    content: "inline*",
    group: "block",

    draggable: true,
    disableDropCursor: true,

    parseHTML() {
        return [
            {
                tag: 'p',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['p', mergeAttributes(HTMLAttributes, { 'data-type': 'draggable-item' }), 0]
    },

    addNodeView() {
        return VueNodeViewRenderer(LotParagraphComponent)
    },

    addKeyboardShortcuts() {
        return {
            'Mod-Enter': () => this.editor.commands.addNewLine(),
        }
    },

    addCommands() {
        return {
            addNewLine: () => ({ tr, chain }) => {
                const candidate = findParentNodeOfType(tr.doc.type.schema.nodes.lotOuvrage)(tr.selection)
                    || findParentNodeOfType(tr.doc.type.schema.nodes.lotText)(tr.selection)
                    || findParentNodeOfType(tr.doc.type.schema.nodes.lotParagraph)(tr.selection)
                    || findParentNodeOfType(tr.doc.type.schema.nodes.paragraph)(tr.selection)

                const end = candidate.pos + candidate.node.nodeSize;
                return chain().insertContentAt(end, {type: "lotParagraph", content: []}).focus().run()
            },
        }
    },
})

export const lotContent = Node.create({
    name: "lotContent",
    priority: 10000,

    extensions: [
        lotParagraph,
        lotOuvrage,
        lotText,
    ],

    content: "(lotParagraph|list|lotOuvrage|lotText|lotGroup|image)+",

    parseHTML() {
        return [
            {
                tag: 'lot-content',
            },
        ]
    },

    addAttributes() {
        return {
            expanded: {
                type: Boolean,
            },
        }
    },

    renderHTML({ HTMLAttributes }) {
        return ['lot-content', mergeAttributes(HTMLAttributes), 0]
    },

    addNodeView() {
        return VueNodeViewRenderer(LotContentComponent)
    },
});