<template>
    <div>
        <div>
            <input v-model="name" type="text" class="form-control" id="name" placeholder="Nom de la tâche" />
        </div>
        <!-- Full form -->
        <div v-if="isEmbedded">
            <div class="mt-4">
                <label class="form-label" for="description">Description</label>
                <textarea v-model="description" type="text" rows="3" class="form-control" id="description" />
            </div>
            <div class="mb-8 mt-4">
                <draggable :list="getItems()" :disabled="!enabled" item-key="id" class="list-group mb-8"
                    handle=".handle" :component-data="{
                        tag: 'div',
                        type: 'transition-group',
                        name: !dragging ? 'flip-list' : null
                    }" @start="dragging = true" @end="dragging = false;" v-bind="dragOptions">
                    <template #item="{ element }">
                        <div class="list-group-item" :class="{ 'not-draggable': !enabled }">
                            <TodoSubItem :todoSubItem="element" @delete="deleteSubItem(element)" @edit="editSubItem" />
                        </div>
                    </template>
                </draggable>
                <TodoSubItemForm v-if="showTodoSubItemForm" @close="showTodoSubItemForm = false"
                    @save="addTodoSubItem" />
                <button v-if="!showTodoSubItemForm"
                        @click="showTodoSubItemForm = true"
                        type="button"
                    class="btn btn-primary">
                    Ajouter une sous-tâche
                </button>
            </div>
            <div class="mt-4">
                <label class="form-label" for="date">Date</label>
                <input v-model="dueDate" type="date" class="form-control" id="date" />
            </div>
            <div class="mt-4">
                <UserSelect v-model="assignedToId" title="Assigné à" />
            </div>
        </div>

        <div class="mt-8 d-flex gap-3">
            <button @click="close" type="button" class="btn btn-secondary">Annuler</button>
            <button v-if="!isSaving" @click="save" :disabled="!canSave()" type="button" class="btn btn-primary">{{
                this.todoItem ? 'Sauvegarder' : 'Ajouter' }}</button>
            <LoadingButton v-if="isSaving"></LoadingButton>
        </div>
    </div>
</template>

<script>
import LoadingButton from '@/views/_core/components/LoadingButton.vue';
import TodoSubItem from '@/views/todos/presentation/todos/components/TodoSubItem.vue';
import UserSelect from '@/views/users/presentation/user-select/UserSelect.vue';
import dayjs from "dayjs";
import draggable from 'vuedraggable';
import { CREATE_TODO_ITEM, DELETE_TODO_ITEM, GET_TODOS, UPDATE_TODO_ITEM } from '../../data/todos_graphql';
import TodoSubItemForm from './TodoSubItemForm.vue';
import { GET_CANEVA_BY_ID_QUERY } from "@/views/canevas/data/canevas_graphql";

export default {
    name: "TodoItemForm",
    props: {
        todoItem: Object,
        todoId: {
            type: String,
            required: true,
        },
        projectId: {
            type: String,
            required: false,
        },
        canevaId: {
            type: String,
            required: false,
        },
        lastOrder: Number,
        autoAssignedToId: String,
        assignedToCurrentUser: {
            type: Boolean,
            required: false,
            default: false,
        },
        isEmbedded: {
            type: Boolean,
            required: false,
            default: false,
        },
        basicInputs: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    components: {
        UserSelect,
        LoadingButton,
        TodoSubItem,
        draggable,
        TodoSubItemForm,
    },
    data() {
        return {
            name: "",
            description: "",
            dueDate: undefined,
            isSaving: false,
            assignedToId: undefined,
            subItems: [],
            showTodoSubItemForm: false,
            dragging: false,
            enabled: true,
        };
    },
    created() {
        this.initializeData();
    },
    methods: {
        initializeData() {
            if (this.todoItem) {
                this.name = this.todoItem.name;
                this.description = this.todoItem.description;
                this.dueDate = this.todoItem.dueDate ? dayjs(this.todoItem.dueDate).format('YYYY-MM-DD') : undefined
                this.assignedToId = this.todoItem.assignedTo?.id;
                this.subItems = [...this.todoItem.todoItems] || [];
            } else if (this.autoAssignedToId) {
                this.assignedToId = this.autoAssignedToId;
            }
        },
        canSave() {
            return this.name?.length;
        },
        close() {
            this.$emit("close");
        },
        getItems() {
            return this.subItems;
        },
        deleteSubItem(item) {
            if (!item.id.startsWith('tmp-')) {
                this.$apollo.mutate({
                    mutation: DELETE_TODO_ITEM,
                    variables: {
                        id: item.id,
                    },
                });
            }
            this.subItems = this.subItems.filter((subItem) => subItem.id != item.id)
        },
        editSubItem(item) {
            this.subItems = this.subItems.map((subItem) => {
                if (subItem.id === item.id) {
                    return item;
                }
                return subItem;
            });
        },
        addTodoSubItem(item) {
            this.subItems = [...this.subItems, {
                id: 'tmp-' + Math.random().toString(36).substring(7),
                name: item.name,
                isDone: false,
            }]
        },
        async save() {
            const apolloClient = this.$apollo;
            this.isSaving = true;
            this.enabled = false;
            let todoItemId;
            // todo batch
            if (this.todoItem) {
                todoItemId = this.todoItem.id;
                try {
                    await apolloClient.mutate({
                        mutation: UPDATE_TODO_ITEM,
                        variables: {
                            id: this.todoItem.id,
                            name: this.name,
                            description: this.description,
                            dueDate: this.dueDate,
                            assignedToId: this.assignedToId == null ? '' : this.assignedToId,
                        },
                        refetchQueries: [
                            {
                                query: GET_TODOS,
                                variables: {
                                    projectId: this.projectId,
                                    assignedToCurrentUser: this.assignedToCurrentUser,
                                }
                            },
                            {
                                query: GET_CANEVA_BY_ID_QUERY,
                                variables: {
                                    id: this.canevaId,
                                },
                                skip: !this.canevaId,
                            }
                        ],
                    });
                }
                catch (error) {
                    console.error('Update todoItem error:', error);
                }
                this.close();
            } else {
                try {
                    const res = await apolloClient.mutate({
                        mutation: CREATE_TODO_ITEM,
                        variables: {
                            name: this.name,
                            description: this.description,
                            dueDate: this.dueDate,
                            projectId: this.projectId,
                            todoId: this.todoId,
                            assignedToId: this.assignedToId,
                            order: (this.lastOrder || 0) + 1,
                        },
                        refetchQueries: [
                            {
                                query: GET_TODOS,
                                variables: {
                                    projectId: this.projectId,
                                    assignedToCurrentUser: this.assignedToCurrentUser,
                                }
                            },
                            {
                                query: GET_CANEVA_BY_ID_QUERY,
                                variables: {
                                    id: this.canevaId,
                                },
                                skip: !this.canevaId,
                            },
                        ],
                    });
                    todoItemId = res.data.todoItemCreate.todoItem.id;
                    this.$emit('close');
                }
                catch (error) {
                    console.error('Create todoItem error:', error);
                }
            }
            if (this.subItems.length) {
                const itemsToCreate = [];
                const itemsToUpdate = [];
                for (let i = 0; i < this.subItems.length; i++) {
                    const item = { ...this.subItems[i] };
                    item.order = i;
                    item.groupId = todoItemId;
                    if (item.id.startsWith('tmp-')) {
                        itemsToCreate.push(item)
                    } else {
                        itemsToUpdate.push(item)
                    }
                }
                // TODO: bulk create
                for (const itemToCreate of itemsToCreate) {
                    await apolloClient.mutate({
                        mutation: CREATE_TODO_ITEM,
                        variables: {
                            name: itemToCreate.name,
                            projectId: this.projectId,
                            todoId: this.todoId,
                            todoGroupId: todoItemId,
                            order: itemToCreate.order,
                            isDone: itemToCreate.isDone,
                        },
                        refetchQueries: [
                            {
                                query: GET_TODOS,
                                variables: {
                                    projectId: this.projectId,
                                    assignedToCurrentUser: this.assignedToCurrentUser,
                                }
                            },
                            {
                                query: GET_CANEVA_BY_ID_QUERY,
                                variables: {
                                    id: this.canevaId,
                                },
                                skip: !this.canevaId,
                            },
                        ],
                    });
                }
                // TODO: bulk update
                for (const itemToUpdate of itemsToUpdate) {
                    await apolloClient.mutate({
                        mutation: UPDATE_TODO_ITEM,
                        variables: {
                            id: itemToUpdate.id,
                            name: itemToUpdate.name,
                            order: itemToUpdate.order,
                            isDone: itemToUpdate.isDone,
                            todoGroupId: itemToUpdate.groupId,
                        },
                        refetchQueries: [
                            {
                                query: GET_TODOS,
                                variables: {
                                    projectId: this.projectId,
                                    assignedToCurrentUser: this.assignedToCurrentUser,
                                }
                            },
                            {
                                query: GET_CANEVA_BY_ID_QUERY,
                                variables: {
                                    id: this.canevaId,
                                },
                                skip: !this.canevaId,
                            },
                        ],
                    });
                }
            }

            this.name = "";
            this.description = "";
            this.due_date = undefined;
            this.subItems = []
            this.enabled = true;
            this.isSaving = false;
            this.$emit('close');
        },
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "todoSubItems",
                ghostClass: "ghost",
            };
        },
    },
}
</script>