import gql from 'graphql-tag'

export const CANEVAS_QUERY = gql`
    query CanevasQuery {
        canevas {
            id,
            name,
            todos {
                id,
                title,
                todoItems {
                    id
                    name
                    description
                    color
                    order
                }
            }
            tags {
                id
                name
                color
            }
        }
    }
`

export const GET_CANEVA_BY_ID_QUERY = gql`
    query GetCanevaByIdQuery($id: UUID!) {
        caneva: canevaById(id: $id) {
            id
            name
            todos {
                id
                title
                order
                createdAt
                updatedAt
                todoItems {
                    id
                    name
                    description
                    color
                    dueDate
                    isDone
                    order
                    todoItems {
                        id
                        name
                        order
                        isDone
                        createdAt
                        updatedAt
                    }
                }
            }
            tags {
                id
                name
                color
            }
        }
    }
`
export const CREATE_CANEVA_MUTATION = gql`
    mutation CreateCanevaMutation($name: String!, $todos: [UUID!]) {
        canevaCreate(name: $name, todoIds: $todos) {
            caneva {
                id,
                name,
                todos {
                    id,
                    title,
                    todoItems {
                        id
                        name
                        description
                        color
                        order
                    }
                }
            }
        }
    }
`

export const DELETE_CANEVA_MUTATION = gql`
    mutation DeleteCanevaMutation($id: UUID!) {
        canevaDelete(id: $id) {
            ok
        }
    }
`

export const UPDATE_CANEVA_MUTATION = gql`
    mutation UpdateCanevaMutation($canevaId: UUID!, $name: String!, $tagIds: [UUID]) {
        canevaUpdate(canevaId: $canevaId, name: $name, tagIds: $tagIds) {
            caneva {
                id,
                name,
                todos {
                    id,
                    title,
                    todoItems {
                        id
                        name
                        description
                        color
                        order
                    }
                }
                tags {
                    id
                    name
                    color
                }
            }
        }
    }
`